import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import LeafletMap from '../components/leafletMap'
import Footer from '../components/footer'

const IndexPage = ({data}) => {

  return (
  <Layout>
    <SEO title="Home" />
    <section className="hero">
      <Img fluid={data.shopFront.childImageSharp.fluid} className="hero-image"/>
    </section>
    <section className="container">
      <span className="description">
      AUGIK±O is a seasoned and trusted seller. You'll find us on&nbsp;
      <a href="https://www.qoo10.sg/shop/harlowaugiko" target="_blank" rel="noopener noreferrer">
        Qoo10</a> and&nbsp; 
      <a href="https://shopee.sg/augiko" target="_blank" rel="noopener noreferrer">
        Shopee</a>. 
      We have products for both men and ladies.  Shop with confidence with us and 
      we look forward to serving you.
      </span>
      <div className="gallery-grid">
        <ClickableImage gql={data} propString="hersLeftTop" className="gallery-img1"/>
        <ClickableImage gql={data} propString="hersLeftBottom" className="gallery-img2"/>
        <ClickableImage gql={data} propString="hersRightPortrait" className="gallery-img3"/>
      </div>
      <div className="about-grid">
        <h2>FOR HER</h2>
        <p>We have great accessories that we love to use ourselves and would love to 
        share them with you.
        </p>
      </div>
      <div className="about-grid">
        <h2>FOR HIM</h2>
        <p>Catered to the Maker in him, we have various specially crafted electronics learning 
        kits and components to make any would-be Maker dreams come true.
        </p>
      </div>
    </section>
    <div className="parallax"></div>
    <section className="container">
      <div className="gallery-grid-mirror">
        <ClickableImage gql={data} propString="hisRightTop" className="gallery-img1"/>
        <ClickableImage gql={data} propString="hisRightBottom" className="gallery-img2"/>
        <ClickableImage gql={data} propString="hisLeftPortrait" className="gallery-img3"/>
      </div>
      <div className="about-grid">
        <p>
        </p>
      </div>
    </section>
    {typeof window !== 'undefined' &&
      <LeafletMap
        position={[1.43667, 103.80596]} // Your Coordinates
        zoom={17} // Zoom Level
        markerText={"AUGIK±O, Woodlands Horizon"} // Icon text
      />
    }
    <Footer />
  </Layout>
  )
}

export const ClickableImage = (props) => {
  return (
    <div className={props.className}>
      <a href={href_links[props.propString]} target="_blank" rel="noopener noreferrer">
        <Img fluid={props.gql[props.propString].childImageSharp.fluid} className="img-cover" />
      </a>
    </div>
  );
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    shopFront: file(relativePath: { eq: "happy_shopping.jpg" }) {
      ...fluidImage
    }
    hersRightPortrait: file(relativePath: { eq: "brushes.jpg" }) {
      ...fluidImage
    }
    hersLeftTop: file(relativePath: { eq: "brushes2.jpg" }) {
      ...fluidImage
    }
    hersLeftBottom: file(relativePath: { eq: "shoes.jpg" }) {
      ...fluidImage
    }
    hisLeftPortrait: file(relativePath: { eq: "augiko_kit.png" }) {
      ...fluidImage
    }
    hisRightTop: file(relativePath: { eq: "mblock_landscape.png" }) {
      ...fluidImage
    }
    hisRightBottom: file(relativePath: { eq: "usb_accessories.png" }) {
      ...fluidImage
    }
  }
`;

const href_links = {
  hersRightPortrait:  "https://www.qoo10.sg/item/HARLOW-AUGIKO-BRUSH-PALM-PADS-EGGS-SQUEAKY-CLEANER-BRUSHES/444916044",
  hersLeftTop:        "https://www.qoo10.sg/item/HARLOW-AUGIKO-BRUSH-PALM-PADS-EGGS-SQUEAKY-CLEANER-BRUSHES/444916044",
  hersLeftBottom:     "https://www.qoo10.sg/item/HARLOW-AUGIKO-BUY-2-FREE-1-HEEL-LINERS-AND-HEEL-PAD-PU-FOAM-SILICONE-NO-BLISTERS/428970458",
  hisRightTop:        "https://www.qoo10.sg/item/HARLOW-AUGIKO-DEMO-CODES-30-PAGE-E-GUIDE-ARDUINO-SENSOR-MODULE-KIT-SG-SELLER/425049726",
  hisRightBottom:     "https://www.qoo10.sg/item/HARLOW-AUGIKO-BUY-2-FREE-1-OTG-TYPE-C-ADAPTER-MICRO-USB-TO-TYPE-C-USB-TO-TYPE/425716203",
  hisLeftPortrait:    "https://www.qoo10.sg/item/HARLOW-AUGIKO-40-PARTS-IN-1-KIT-55-OFF-DEAL-ARDUINO-LAUNCHER-KIT-ENHANCED/485038534"
};

export default IndexPage
