import React from 'react'

const Footer = () => {

  return (
    <footer>
      <section>
        <div className="hours">
          <h3>WEBSITES</h3>
          <span>
            <a href="https://www.qoo10.sg/shop/harlowaugiko" target="_blank" rel="noopener noreferrer">
              Qoo10
            </a>
          </span>
          <span>
            <a href="https://shopee.sg/augiko" target="_blank" rel="noopener noreferrer">
              Shopee
            </a>
          </span>
        </div>
        <div className="address">
          <h3>ADDRESS</h3>
          <span>31 Woodlands Close,</span>
          <span>Woodlands Horizon,</span>
          <span>Singapore 737855</span>
        </div>
        <div className="contact">
          <h3>CONTACT</h3>
          <span></span>
          <span>augiko at augik.com</span>
        </div>
      </section>
      <p>Re-built by
        <a href="https://www.augik.com">AUGIK±O</a>
      </p>
      <p>Designed by
        <a href="https://crollacreations.com" target="_blank" rel="noopener noreferrer">crollacreations</a>
      </p>
    </footer>
  )
}

export default Footer;